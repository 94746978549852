import Vue from 'vue'
import VueRouter from 'vue-router'
import Search from '../views/Search.vue'
import CertificateInfo from '../views/CertificateInfo.vue'
import List from '../views/List.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Search',
    component: Search
  },
  {
    path: '/detail',
    name: 'CertificateInfo',
    component: CertificateInfo
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
